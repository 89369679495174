.InputWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  font-size: 16px;
  font-family: 'Roboto';
  padding: 9px 2px 0;
  max-width: 301px;
}

.InputWrapper input {
  width: 100% !important;
  height: 32px !important;
  border-radius: 2px !important;
  min-width: 250px;
  border: 1px solid #CCD1D3 !important; /* Replace with your actual color for neutral30 */
  padding-left: 48px !important;
}

.InputWrapper input.error {
  border-color: #F44336 !important; /* Replace with your actual color for errorMedium */
}

.InputWrapper .flag-dropdown {
  border: 1px solid #CCD1D3 !important; /* Replace with your actual color for neutral30 */
}

.InputWrapper .flag-dropdown.error {
  border-color: #F44336 !important; /* Replace with your actual color for errorMedium */
}

.LabelWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
